<template lang="html">
    <div>
        <div>
            <a v-if="!show_columns_selector" href="javascript:void(0);" @click="toggle_columns_selector">Show Columns Selector</a>
            <a v-if="show_columns_selector" href="javascript:void(0);" @click="toggle_columns_selector">Hide Columns Selector</a>
            <div v-if="show_columns_selector" style="display: flex; justify-content: center; height: 64vh;">
                <div v-for="[group_name, columnsOrSubGroup] of Object.entries(columns_groups)" v-if="show_columns_selector" style="display: flex; flex-direction: column; height: 100%;">
                    <div v-if="show_columns_selector" style="margin-top: 0.8vh; display: flex; flex-direction: column; padding: 2vh 2vw 2vh 2vw; height: 100%; border: 1px dotted black;">
                        <h4>
                            <input 
                            :ref="group_name"
                            type="checkbox"
                            :checked="are_all_checked(group_name)"
                            @change="toggle_all_columns_in_group(group_name, $event.target.checked)">
                            {{ group_name }}
                        </h4>
                        <div v-if="Array.isArray(columnsOrSubGroup)">
                            <div v-for="field of fields">
                                <div v-if="columnsOrSubGroup.includes(field.name)">
                                    <input 
                                    :disabled="field.lock_column"
                                    type="checkbox"
                                    @click="toggle_column(field.toggle_column, group_name, field.name)"
                                    :checked="show_c[field.toggle_column]"> {{ field.label }}
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!Array.isArray(columnsOrSubGroup)">
                            <div v-for="[sub_group, columns] of Object.entries(columnsOrSubGroup)">
                                <h6 style="padding-left: 2vw; margin-top: 0.8vh;">
                                    <input 
                                    :ref="sub_group"
                                    type="checkbox"
                                    :checked="are_all_checked(sub_group)"
                                    @change="toggle_all_columns_in_sub_group(group_name, $event.target.checked, sub_group)">
                                    {{ sub_group }}
                                </h6>
                                <div v-for="column of columns">
                                    <template v-for="currentField in [fields.find(field => field.name === column)]">
                                    <div v-if="columns.length > 1" style="padding-left: 4vw;">
                                        <input 
                                        :disabled="fields.find(field => field.name === column).lock_column"
                                        type="checkbox"
                                        @click="toggle_column(currentField.toggle_column, group_name, currentField.name, sub_group)"
                                        :checked="show_c[currentField.toggle_column]"> {{ currentField.label }}
                                    </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span style="display: none;">{{ r }}</span>
        </div>
        <div style="overflow: auto;">
            <table :style="{ height: tableHeight, width: '6000px' }" class="offers_tbl">
            <tr class="table_header_row bottom_border sticky_header" sticky_header>
                <th class="noresize_td" style="width: 30px;" v-if="! no_actions">
                    
                </th>
                <template v-for="field in fields">
                    <th v-if="show_c[field.toggle_column]" class="resize_td selectable_columns left_border">
                        <div>
                            <div class="th-title"  @click="sortByField(field.name)">
                                {{ field.label }}
                            </div>
                            <div class="th-icon" v-if="sortOptions.sortByField === field.name">
                                <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                            </div>
                        </div>
                    </th>
                </template>
                <th v-if="show_columns_name === 'update_revenue'">
                    
                </th>
            </tr>

            <tr v-if="typeof totalRow !== 'undefined' && Object.keys(totalRow).length !== 0">
                <td class="noresize_td" v-if="! no_actions">
                    <div>
                        
                    </div>
                </td>
                <template v-for="field in fields">
                    <td class="resize_td left_border" v-if="show_c[field.toggle_column]">
                        <span class="'total-row'" v-if="field.revenue && (field.name === 'revenue' || field.name === 'global_offers_count')" >{{ totalRow[field.name] | numFormat }}</span>
                        <span v-else :class="'total-row'">{{ totalRow[field.name] }}</span>
                    </td>
                </template>
            </tr>

            <tr v-for="(r, i) in rows_data_c" :class="i%2 == 0 ? 'grey-bg-td' : ''" v-if="r.show" :key="i">
                <td class="noresize_td" v-if="! no_actions">
                    <div class="div-actions" v-if="index_actions[r.id]">
                        <div v-if="show_columns_name == 'dash'">
                            <a target="_blank" :href="'/admin/dash_global_details?id=' + r.id +'&dd_from='+ dd_from +'&dd_to=' + dd_to + '&geo_os_selected=' + geo_os_selected + '&appname=' + r.appname + '&bundle_id=' + r.bundle_id + '&advertiser=' + r.advertiser">Show Global Offers Data</a><br />
                            <a target="_blank" :href="'/admin/dash_seats_details?id=' + r.id +'&dd_from='+ dd_from +'&dd_to=' + dd_to + '&geo_os_selected=' + geo_os_selected + '&appname=' + r.appname + '&bundle_id=' + r.bundle_id + '&advertiser=' + r.advertiser"">Show AF Seats Data</a><br />
                            <a target="_blank" :href="'/admin/dash_adv_details?id=' + r.id">Show Advertisers Data</a>
                        </div>
                        <a :class="{'disabled': !(typeof r.edit !== 'undefined' && r.edit.length !== 0 && r.edit !== 'javascript:void(0)') ? true : false}" :href=r.edit v-if="typeof r.edit !== 'undefined'">{{ edit_label }}</a> <br v-if="typeof r.edit !== 'undefined'">
                        <a v-if="show_columns_name != 'dash'" href="javascript:void(0);" @click="onDelete(r)">Delete</a><br v-if="show_columns_name != 'dash'">
                        <a :class="{'disabled': (typeof r.replace === 'undefined' || !r.replace) ? true : false}" :href="'javascript:void(0);'" @click="replace(r.id)" v-if="typeof r.replace !== 'undefined'">{{ replace_label }}</a v-if="typeof r.replace !== 'undefined'">
                        <a :class="{'disabled': (typeof r.download === 'undefined' || !r.download) ? true : false}" :href="'javascript:void(0);'" @click="download(r)" v-if="typeof r.download !== 'undefined'">Download</a>
                    </div>
                    <div>
                        <button class="action-btn" :style="{ backgroundColor: r['color'] === 'yellow' ? '#F6E96E' : r['color'] === 'red' ? '#EF9A9A' : '' }" @click="toggle_open_actions(r.id)">...</button>
                    </div>
                </td>
                <template v-for="field in fields">
                    <td class="resize_td left_border" v-if="show_c[field.toggle_column]">
                        <span v-if="field.checkbox"><input :disabled="true" type="checkbox" :checked="r[field.name] === 1 ? true : false"></span>
                        <span v-else-if="((field.revenue && field.name === 'revenue') || (field.num_format && typeof field.num_format !== 'undefined')) && typeof field.checkbox == 'undefined'">  {{ r[field.name] === '-' ? '-' : (r[field.name] /* | numFormat */) }} </span>
                        <span v-else-if="field.is_preview_link && typeof field.is_preview_link !== 'undefined' "> 
                            <a class="btn btn-primary" v-if="r['os'] === 'iOS'" :href="r[field.name]" target="_blank">App Store</a>
                            <a class="btn btn-primary" v-if="r['os'] === 'Android'" :href="r[field.name]" target="_blank">Play Store</a>
                        </span>
                        <span v-else>{{ r[field.name] }}</span><span v-if="field.precentage_format && typeof field.precentage_format !== 'undefined'"> % </span>
                        <span v-if="typeof field.dollar_sign !== 'undefined' && field.dollar_sign === true"> $ </span>
                    </td>
                </template>
                <td v-if="show_columns_name === 'update_revenue'">
                    <a class="btn-success btn" :href="'/man/update-manual-revenue-edit?id=' + r.id">Update Revenue</a> <a v-if="user.name === 'Adir Miedvietzky' || user.name === 'ran'" class="btn-success btn" @click="onDelete(r)" href="javascript:void(0);">Delete Preset</a> 
                </td>
            </tr>
        </table>
        </div>
    </div>
</template>
  
<script>
/* 
    fileds: [
        {
            name: '',
            label: '',
            sortable: true,
            toggle_column: 1
            show_c: true
        }
    ]

*/
import moment, { relativeTimeThreshold } from 'moment';
export default {
    name: 'ResizableDataTableDash',
    props: {
        rows_data: {
            type: Array,
            required: true,
            default: () => []
        },
        tableHeight: {
            type: String,
            default: '800px'
        }, 
        nonIntFields: {
            type: Array,
            default: () => []
        }, 
        floatFields: {
            type: Array,
            default: () => []
        }, 
        fields: {
            type: Array,
            default: () => []
        }, 
        booleanFields: {
            type: Array,
            default: () => []
        },
        dateFileds: {
            type: Array,
            default: () => []
        },
        revenueFields: {
            type: Array,
            default: () => []
        },
        show_c: {
            type: Object,
            required: true,
            default:() => {}
        },
        delete: {
            type: Function,
            required: true,
            default: null
        },
        edit: {
            type: String,
            default: ''
        },
        edit_label: {
            type: String,
            default: 'Edit'
        },
        replace: {
            type: Function,
            default: null
        },
        replace_label: {
            type: String,
            default: 'Replace'
        },
        download: {
            type: Function,
            required: false,
            default: null
        },
        show_columns_name: {
            type: String,
            default: null
        },
        totalRow: {
            type: Object,
            default: () => {}
        },
        dd_from : {
            type: String,
            default: ''
        },
        dd_to : {
            type: String,
            default: ''
        },
        no_actions: {
            type: Boolean,
            default: false
        },
        geo_os_selected : {
            type: String,
            default: ''
        },
        goToStoreFunction:{
            type: Function,
            required: false,
            default: null
        },
    },
    data() {
        return {
            show_columns_selector: false,

            r: 0,
            sortOptions: { 
                sortOrder: 'desc',
                sortByField: 'id'
            },
            filterForm: {
                id: null
            },
            index_actions: {},
            current_list: [],
            origin_list: [],
            columns_groups: [],
            states_of_columns_in_groups: {},
            group_to_sub_groups : {},
        }
    },
    async created() {
        for (const k in this.rows_data) {
            this.rows_data[k].show = true;
        }

        for (const k in this.rows_data) {
            if (this.show_columns_name === 'dsp-automator') {
                this.index_actions[this.rows_data[k].id] = false;
            }
            if (typeof this.rows_data[k].id === 'number' && this.rows_data[k].id !== 0) {
                this.index_actions[this.rows_data[k].id] = false;
            }
            if (this.show_columns_name == 'dash') {
                this.index_actions[this.rows_data[k].id] = false;
            }
        }

        let show_c_name = `show_c_${this.show_columns_name}`;
        if (localStorage.getItem(show_c_name) ) {
            this.$emit('update:show_c', JSON.parse(localStorage.getItem(show_c_name)));
        } else {
            localStorage.setItem(show_c_name, JSON.stringify(this.show_c));
        }

        this.origin_list = this.rows_data;
        this.columns_groups = {
            General: ['appname', 'advertiser', 'os', 'bundle_id', 'mmp', 'is_sub_data_available'],
            Revenue: ['advertiser_revenue', 'affise_revenue'],
            Traffic: {
                Impressions: ['prime_served_impressions', 'affise_impressions', 'mmp_impressions', 'prime_to_affise_served_percent_impressions',
                                'prime_to_mmp_served_percent_impressions'],
                Clicks: ['prime_served_clicks', 'affise_clicks', 'mmp_clicks', 'prime_to_affise_served_percent_clicks',
                            'prime_to_mmp_served_percent_clicks']
            },
            Performance: {
                RPM : ['rpm'],
                Conversions : ['conversions'],
                CR : ['cr'],
                'Affise Data': ['affise_approved_conversion', 'affise_declined_conversion', 'affise_total_conversion'],
                'AppsFlyer Data': ['appsflyer_total_conversion', 'appsflyer_installs', 'appsflyer_reattributions', 'appsflyer_reengagements', 'appsflyer_cr'],
                'AppsFlyer Protect360': ['p360_fraudlent_attribution_percentage', 'p360_post_attribution_fraud', 'p360_real_time_blocks', 'p360_total_fraudlent_attribution', 'p360_total_installs']

            }
        }

        this.group_to_sub_groups = {
            Traffic: ['Impressions', 'Clicks'],
            Performance: ['RPM', 'Conversions', 'CR', 'Affise Data', 'AppsFlyer Data', 'AppsFlyer Protect360']
        }
        this.states_of_columns_in_groups = {
            General: { appname: true, advertiser: true, os: true, bundle_id: true, mmp: true, os: true, is_sub_data_available: true  },
            Revenue: { advertiser_revenue: true, affise_revenue: true, Theme: true },
            Traffic: { prime_served_impressions: true, affise_impressions: true, mmp_impressions: true, prime_to_affise_served_percent_impressions: true,
                prime_to_mmp_served_percent_impressions: true, prime_served_clicks: true, affise_clicks: true, mmp_clicks: true, prime_to_affise_served_percent_clicks: true,
                prime_to_mmp_served_percent_clicks: true
            },
            Performance: { rpm: true, conversions: true, cr: true, affise_approved_conversion: true, affise_declined_conversion: true, affise_total_conversion: true,
                appsflyer_total_conversion: true, appsflyer_installs: true, appsflyer_reattributions: true, appsflyer_reengagements: true, appsflyer_cr: true, p360_fraudlent_attribution_percentage: true, p360_post_attribution_fraud: true, p360_real_time_blocks: true, p360_total_fraudlent_attribution: true, p360_total_installs: true
            },
            Impressions: { prime_served_impressions: true, affise_impressions: true, mmp_impressions: true, prime_to_affise_served_percent_impressions: true, prime_to_mmp_served_percent_impressions: true},
            Clicks: { prime_served_clicks: true, affise_clicks: true, mmp_clicks: true, prime_to_affise_served_percent_clicks: true, prime_to_mmp_served_percent_clicks: true },
            RPM : { rpm: true },
            CR : { cr: true },
            Conversions : { conversions: true },
            'Affise Data': { affise_approved_conversion: true, affise_declined_conversion: true, affise_total_conversion: true },
            'AppsFlyer Data': { appsflyer_total_conversion: true, appsflyer_installs: true, appsflyer_reattributions: true, appsflyer_reengagements: true, appsflyer_cr: true, p360_fraudlent_attribution_percentage: true, p360_post_attribution_fraud: true, p360_real_time_blocks: true, p360_total_fraudlent_attribution: true, p360_total_installs: true },
            'AppsFlyer Protect360': { p360_fraudlent_attribution_percentage: true, p360_post_attribution_fraud: true, p360_real_time_blocks: true, p360_total_fraudlent_attribution: true, p360_total_installs: true }
        }

    },
    // filters: {
    //     numFormat(v) {
    //         console.log('numFormat', v);
            
    //         let i = isNaN(v) ? 0 : v
    //         return(Number(i).toLocaleString())
    //     },
    // },
    methods: {
        numFormat(v) {
            if (v === '-') {
                return v;
            }

            let i = isNaN(v) ? 0 : v
            return(Number(i).toLocaleString())
        },
        toggle_open_actions(id) {
            for (const k in this.index_actions) {
                if (id.toString() === k) {
                    this.index_actions[k] = !this.index_actions[k];
                    
                } else {
                    this.index_actions[k] = false;
                }
            }
            this.$forceUpdate();
        },
        toggle_columns_selector() {
            this.show_columns_selector = !this.show_columns_selector;
        },
        toggle_column(i, group_name, fieldName, sub_group = null) {
            this.$emit('update:show_c', { ...this.show_c, [i]: !this.show_c[i] });
            let show_c_name = `show_c_${this.show_columns_name}`;
            this.$set(this.show_c, i, !this.show_c[i]);
            localStorage.setItem(show_c_name, JSON.stringify(this.show_c));
            if (sub_group) {
                this.states_of_columns_in_groups[sub_group][fieldName] = !this.states_of_columns_in_groups[sub_group][fieldName];
                this.$refs[sub_group].checked = this.are_all_checked(sub_group);
            }
            this.states_of_columns_in_groups[group_name][fieldName] = !this.states_of_columns_in_groups[group_name][fieldName];
            this.$refs[group_name].checked = this.are_all_checked(group_name);
        },
        async onDelete(row) {
            this.delete(row);
        },
        sortByField(fieldName) {
            this.sortOptions.sortByField = fieldName
            if (this.sortOptions.sortOrder === 'desc') {
                this.sortOptions.sortOrder = 'asc'
            } else {
                this.sortOptions.sortOrder = 'desc'
            }
            const dateFields = this.dateFileds;
            const nonIntFields = this.nonIntFields;
            const floatFields = this.floatFields;
            const booleanFields = this.booleanFields;
            const revenueFields = this.revenueFields;
            
            if (revenueFields.includes(fieldName)) {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => {
                        const numA = parseFloat(a[fieldName]);
                        const numB = parseFloat(b[fieldName]);
                        return numB - numA;
                    });
                } else {
                    this.rows_data.sort((a, b) => {
                        const numA = parseFloat(a[fieldName]);
                        const numB = parseFloat(b[fieldName]);
                        return numA - numB;
                    });
                }
            } else if (booleanFields.includes(fieldName)) {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : -1)
                } else {
                    this.rows_data.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : -1)
                }
                // int
            } else if (dateFields.includes(fieldName)) {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => moment(b[fieldName], "DD.MM.YYYY HH:mm").valueOf() - moment(a[fieldName], "DD.MM.YYYY HH:mm").valueOf());
                } else {
                    this.rows_data.sort((a, b) => moment(a[fieldName], "DD.MM.YYYY HH:mm").valueOf() - moment(b[fieldName], "DD.MM.YYYY HH:mm").valueOf());
                }
                // int
            } else if (! nonIntFields.includes(fieldName) && ! floatFields.includes(fieldName) && ! dateFields.includes(fieldName)) {
                    this.rows_data.sort((a, b) => {
                        const valA = a[fieldName] === '-' ? NaN : Number(a[fieldName], 10);
                        const valB = b[fieldName] === '-' ? NaN : Number(b[fieldName], 10);

                        if (isNaN(valA) && isNaN(valB)) {
                            return 0; 
                        } else if (isNaN(valA)) {
                            return this.sortOptions.sortOrder === 'desc' ? 1 : -1; 
                        } else if (isNaN(valB)) {
                            return this.sortOptions.sortOrder === 'desc' ? -1 : 1;
                        }

                        if (this.sortOptions.sortOrder === 'desc') {
                            return valB - valA;
                        } else {
                            return valA - valB;
                        }
                    });
            } else if (floatFields.includes(fieldName)) {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : -1)
                } else {
                    this.rows_data.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : -1)
                }
            } else {    
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => {
                        if (a[fieldName] === "") return 1;  
                        if (b[fieldName] === "") return -1; 
                        return (a[fieldName].toLowerCase() < b[fieldName].toLowerCase()) ? 1 : -1;
                    });
                } else {
                    this.rows_data.sort((a, b) => {
                        if (a[fieldName] === "") return 1; 
                        if (b[fieldName] === "") return -1;
                        return (a[fieldName].toLowerCase() > b[fieldName].toLowerCase()) ? 1 : -1;
                    });
                }
            }
        },
        filterData() {
            let filterID = this.filterForm.id.trim()
            this.rows_data.forEach(v => v.show = true)

            if (filterID) {
                filterID = filterID.toString().toLowerCase()
                for (const k in this.rows_data) {
                    let v = this.rows_data[k]
                    let to_add = false;

                    if (v.id.toString().includes(filterID)) {
                        this.rows_data[k].show = true;
                        to_add = true;
                    }
                    if ( v.goffer_bundle_id.toString().includes(filterID)) {
                        this.rows_data[k].show = true;
                        to_add = true;
                    }

                    if (v.name.toLowerCase().includes(filterID)) {
                        this.rows_data[k].show = true;
                        to_add = true;
                    }

                    if (v.affiseID.toString().includes(filterID)) {
                        this.rows_data[k].show = true;
                        to_add = true;
                    }

                    if (to_add) {
                        this.rows_data[k].show = true;
                    } else {
                        this.rows_data[k].show = false;
                    }
                }
            }
        },

        toggle_all_columns_in_group(group_name, is_checked) {
            for (const field of this.fields) {
                if (Array.isArray(this.columns_groups[group_name])){
                    if (this.columns_groups[group_name].includes(field.name) && (is_checked || (!is_checked && !field.lock_column))) {
                        this.$set(this.show_c, field.toggle_column, is_checked);
                    }
                } else {
                    const group_sub_groups = this.group_to_sub_groups[group_name];
                    for (const sub_group of group_sub_groups) {
                        if (this.columns_groups[group_name][sub_group].includes(field.name) && (is_checked || (!is_checked && !field.lock_column))) {
                            this.$set(this.show_c, field.toggle_column, is_checked);
                            this.$set(this.states_of_columns_in_groups[sub_group], field.name, is_checked)
                            this.$refs[sub_group].checked = this.are_all_checked(group_name);
                        }
                    }
                }
            }
            this.$emit('update:show_c', { ...this.show_c });
            let show_c_name = `show_c_${this.show_columns_name}`;
            localStorage.setItem(show_c_name, JSON.stringify(this.show_c));
        },

        toggle_all_columns_in_sub_group(group_name, is_checked, sub_group) {
            for (const field of this.fields) {
                if (this.columns_groups[group_name][sub_group].includes(field.name) && (is_checked || (!is_checked && !field.lock_column))) {
                    this.$set(this.show_c, field.toggle_column, is_checked);
                    this.$refs[group_name].checked = this.are_all_checked(sub_group);
                    this.states_of_columns_in_groups[group_name][field.name] = !this.states_of_columns_in_groups[group_name][field.name];
                }
            }
            this.$refs[group_name].checked = this.are_all_checked(group_name);
            this.$emit('update:show_c', { ...this.show_c });
            let show_c_name = `show_c_${this.show_columns_name}`;
            localStorage.setItem(show_c_name, JSON.stringify(this.show_c));
        },

        are_all_checked(group) {
            // return Object.values(this.states_of_columns_in_groups[group]).every(Boolean);
            let all_checked = true;
            for (let k1 in this.states_of_columns_in_groups[group]) {
                for (let k2 in this.fields) {
                    if (this.fields[k2].name === k1) {
                        if (!this.show_c[this.fields[k2].toggle_column]) {
                            all_checked = false;
                            break;
                        }
                    }
                }
            }
            return all_checked;
        },
    },
    computed: {
        rows_data_c() {
            // format the data 
            return this.rows_data.map(val => {
                let obj = { ...val }
                for (const k in this.fields) {
                   if (this.fields[k].num_format) {
                        obj[this.fields[k].name] = this.numFormat(obj[this.fields[k].name])
                   }
                }
                return obj;
            
            });
        }, 
        dd_from_c() {
            console.log('dd_from_c', this.dd_from)
            return this.dd_from;
        }
    },
    watch: {
        ["filterForm.id"](val) {
            this.filterData()
        }
    }
}
</script>
  
<style lang="css" scoped>
.offers_tbl {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

}

.resize_td {
    resize: both;
    overflow: auto;
    padding: 5px;
    min-width: 60px;
    max-width: 110px;
    text-align: center;
}

.noresize_td {
    padding: 10px;
    position: relative;
}

td {
    font-size: 14px;
}

.left_border {
    border-left: 1px dotted gray;
}

.bottom_border {
    border-bottom: 1px solid gray;
}
.table_header_row {
    font-weight: bold;
}

.th-title {
    display: inline-block;
    cursor: pointer;
}

.th-icon {
    display: inline-block; 
    padding-left: 2px;
}

.grey-bg-td {
    background-color: rgb(209, 209, 209);
}

.global-offers-view { 
    padding-top: 40px;
    width: 100%;
}

.tr {
    padding-top: 10px;
}

button {
    background-color: tomato;
}

.div-actions {
    position: absolute;
    background: rgb(238, 239, 239);
    border: 1px  rgb(160, 160, 160);
    box-shadow: 2px rgb(160, 160, 160);
    border-radius: 10px;
    z-index: 1012;
    min-width: 180px;
    max-width: 180px;
    padding: 15px 10px;
    margin-top: 30px;
    margin-left: 15px; 
}

.action-btn {
    background: transparent;
    border: transparent;
}

.filter-select {
    width: 300px;
    margin-left: 16px;
    padding: 6px 7px;
    border-radius: 3px;
}

table {
  display: block;
  overflow-y: scroll;
}

.sticky_header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 9999;
    word-break: break-word;
}

.hide_mobile_only {
    display: none;
} 

a.disabled {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: inherit;
}

.total-row {
    font-weight: bold;
    font-size: larger;
}

.link_btn {
    background-color: #42a9d5;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    padding: 5px;
    cursor: pointer;
    border-radius: 20px;
}

.link_btn:hover {
    background-color: #42a9d5;
    font-size: larger;
}
</style>
