<template>
<div class="all">
    <h1>V2</h1>
    <!-- <form class="report-form-1" @submit.prevent=''>
        <DateRange :disabled='reportLoading' :onChange='onDatesChange' :from_='reportForm.startDate' :to_='reportForm.endDate' />
        <div class="div-1412">
            <button :disabled='reportLoading' @click='onReportSubmit' class="btn btn-success">Update Stats</button>
            <i class="spinner fa fa-circle-o-notch fa-spin" v-if='reportLoading'></i>
        </div>
    </form> -->

    <!-- date range -->
    <form @submit.prevent=''>
        <div>
            <div>
                <date-range-picker
                    ref="picker"
                    v-model="dateRange"
                    opens="right"
                    :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }"
                    :ranges="ranges"
                    @update="logEvent"
                    @toggle="logEvent('toggle', $event)"
                    @start-selection="logEvent('event: startSelection', $event)"
                    @finish-selection="logEvent('event: finishSelection', $event)"
                    :disabled='reportLoading'
                    :maxDate="maxDate"
                >
                </date-range-picker>
            </div> 
        </div>
        <div class="div-1412">
            <i class="spinner fa fa-circle-o-notch fa-spin" v-if='reportLoading'></i>
        </div>
    </form>

    <div class="filter-form-container">
        <form class="" @submit.prevent=''>
            <h5>Filter</h5>
            <div class="form-inputs">
                <label>Smartlink name / ID:</label>
                <input ref="filter-sl-id" class="def filter-pl-name-input" v-model='filterForm.id'>
            </div>
        </form>
    </div>

    <div style="margin-top: 15px; display: flex; justify-content: space-between; width: 500px;">
        <div style="display: flex; align-items: center; justify-content: center;">
            <input
                style="display: flex; margin-right: 4px;"
                type="checkbox"
                @change="showZeroRows($event)"
                v-model="showZeros"
                />
                Show zeros rows
        </div>
    </div>

    <hr>

    <div class="sl-list" v-if='slList'>
        <ul class="sl-ul">
            <li class="sl-th">
                <span class="small-width w-span" style=""></span>
                <span class="sortable-column id-c-1 small-width w-span-1" :class='{active: sort.field === "id"}' @click='onColumnClick("id")'>ID</span>
                <span class="sortable-column w-span-6" :class='{active: sort.field === "name"}' @click='onColumnClick("name")'>Name</span>
                <span class="sortable-column w-span-6" :class='{active: sort.field === "status"}' @click='onColumnClick("status")'>Status</span>
                <span class="sortable-column w-span-6" :class='{active: sort.field === "group"}' @click='onColumnClick("group")'>Group</span>
                <span class="sortable-column w-span-5" :class='{active: sort.field === "total_requests"}' @click='onColumnClick("total_requests")'>Requests</span>
                <span class="sortable-column w-span-5" :class='{active: sort.field === "served_total"}' @click='onColumnClick("served_total")'>Served</span>
                <span class="sortable-column w-span-5" :class='{active: sort.field === "blocked_total"}' @click='onColumnClick("blocked_total")'>Lost</span>
                <span class="sortable-column small-width w-span-1" :class='{active: sort.field === "blocked_total_percentage"}' @click='onColumnClick("blocked_total_percentage")'>Lost %</span>
                <span class="sortable-column w-span-4" :class='{active: sort.field === "idfa_frq_errors"}' @click='onColumnClick("idfa_frq_errors")'>IDFA FRQ</span>
                <span class="sortable-column w-span-4" :class='{active: sort.field === "ip_frq_errors"}' @click='onColumnClick("ip_frq_errors")'>IP FRQ</span>
                <span class="sortable-column w-span-3" :class='{active: sort.field === "delivery_frq_errors"}' @click='onColumnClick("delivery_frq_errors")'>Delivery FRQ</span>
                <span class="sortable-column w-span-4" :class='{active: sort.field === "sl_subs_errors"}' @click='onColumnClick("sl_subs_errors")'>SL WL / BL</span>
                <span class="sortable-column w-span-3" :class='{active: sort.field === "offer_subs_errors"}' @click='onColumnClick("offer_subs_errors")'>WL / BL</span>
                <span class="sortable-column w-span-3" :class='{active: sort.field === "adset_errors"}' @click='onColumnClick("adset_errors")'>AdSet Targeting</span>
                <span class="sortable-column w-span-3" :class='{active: sort.field === "offer_errors"}' @click='onColumnClick("offer_errors")'>Offer Targeting</span>
                <span class="sortable-column w-span-3" :class='{active: sort.field === "sl_offer_errors"}' @click='onColumnClick("sl_offer_errors")'>SL / Offer Targeting</span>
                <span class="sortable-column w-span-4" :class='{active: sort.field === "other_errors"}' @click='onColumnClick("other_errors")'>SL / Offer Paused</span>
            </li>
            <li v-if='sumRow' class="sl-t sum-row">
                <span class="small-width w-span"></span>
                <span class="id-c-1 small-width w-span-1"></span>
                <span class="w-span-6"></span>
                <span class="w-span-6"></span>
                <span class="w-span-6"></span>
                <span class="w-span-5">{{sumRow.total_requests | numFormat}}</span>
                <span class="w-span-5">{{sumRow.served_total | numFormat}}</span>
                <span class="w-span-5">{{sumRow.blocked_total | numFormat}}</span>
                <span class="small-width w-span-1">{{sumRow.blocked_total_percentage | percentageFormat}}</span>
                <span class="w-span-4">{{sumRow.idfa_frq_errors | numFormat}}</span>
                <span class="w-span-4">{{sumRow.ip_frq_errors | numFormat}}</span>
                <span  class="w-span-3">{{sumRow.delivery_frq_errors | numFormat}}</span>
                <span class="w-span-4">{{sumRow.sl_subs_errors | numFormat}}</span>
                <span class="w-span-3">{{sumRow.offer_subs_errors | numFormat}}</span>
                <span  class="w-span-3">{{sumRow.adset_errors | numFormat}}</span>
                <span  class="w-span-3">{{sumRow.offer_errors | numFormat}}</span>
                <span  class="w-span-3">{{sumRow.sl_offer_errors | numFormat}}</span>
                <span class="w-span-4">{{sumRow.other_errors | numFormat}}</span>
            </li>

            <template v-for='sl in slList.filter(v => v.show)'>
                <li class="sl-t" :key='sl.id'>
                    <span @click='toggleAdsetExpand(sl.id)' class="plus-td small-width w-span" v-if='sl.new_stats.offers_stats'>
                        <i aria-hidden="true" class="fa fa-plus-circle"></i>
                    </span>
                    <span v-else class="w-span">
                    </span>
                    <span class="po id-c-1 small-width w-span-1">
                        <a :href="'/apps/smartlinks/campaigns/' + sl.id">{{sl.id}}</a>
                    </span>
                    <span class="po w-span-6">
                        <a :href="'/apps/smartlinks/campaigns/' + sl.id">{{sl.name}}</a>
                    </span>
                    <span class="w-span-6">
                        <b-button v-if="(groupIdActive(sl.group_id) && !sl.group_forced )" disabled size="sm">
                            <span>SCHEDULE</span>
                        </b-button>

                        <b-button variant="danger" v-if="(groupIdActive(sl.group_id) && !sl.group_forced)" @click="toggleForceGroup(sl)" size="sm">
                            <span> FORCE </span>
                        </b-button>

                        <button v-if="(sl.group_forced || sl.group_id === 0 || !groupIdActive(sl.group_id))" type="button" class="status-btn" :class='{active: sl.status==="ACTIVE", paused: sl.status==="PAUSED"}' @click='toggleSlStatus(sl)'>
                            {{sl.status}}
                        </button>

                        <b-button variant="success" v-if="sl.group_forced" class="def-cursor" size="sm" @click="toggleForceGroup(sl)">
                            <span>UNFORCE</span>
                        </b-button>

                    </span>

                    <span id="group-cell" class="w-span-6">
                        <b-form-select size="sm" id="input-6" v-model="sl.group_id" :options="groupList" value-field="id" text-field="name" required @change="setGroupHandler(sl)"></b-form-select>
                        <div v-if="(groupIdActive(sl.group_id) && !sl.group_forced )">
                            <div v-if="getGroupStatus(sl.group_id) === 'ACTIVE'" style="color: green; font-size: 10px;">
                                Group is {{ getGroupStatus(sl.group_id) }}
                            </div>
                            <div v-if="getGroupStatus(sl.group_id) !== 'ACTIVE'" style="color: red; font-size: 10px;">
                                Group is {{ getGroupStatus(sl.group_id) }}
                            </div>
                        </div>
                    </span>

                    <span class="f-s-btns-2021 w-span-5">
                        <span>
                            {{sl.new_stats.total_requests | numFormat}}
                        </span>
                        <span>
                            <button v-show='userHasPermission("all-requests-report") && sl.new_stats.total_requests > 0' @click='generateReport(sl.id, "requests")' type="button" class="btn btn-no-padding">
                                <i class="fa fa-download" aria-hidden="true"></i>
                            </button>
                        </span>
                    </span>
                    <span class="w-span-5">{{sl.new_stats.served_total | numFormat}}</span>
                    <span class="f-s-btns-2021 w-span-5">
                        <span>
                            {{sl.new_stats.blocked_total | numFormat}}
                        </span>
                        <span>
                            <button v-show='sl.new_stats.blocked_total > 0' @click='generateReport(sl.id, "blocked")' type="button" class="btn btn-no-padding">
                                <i class="fa fa-download" aria-hidden="true"></i>
                            </button>
                        </span>
                    </span>
                    <span id="w-span-1" :class='{ alert_bad: sl.new_stats.blocked_total_percentage > 20 }'>{{sl.new_stats.blocked_total_percentage | percentageFormat}}</span>
                    <span class="w-span-4">{{sl.new_stats.idfa_frq_errors | numFormat}}</span>
                    <span class="w-span-4">{{sl.new_stats.ip_frq_errors | numFormat}}</span>
                    <span class="w-span-3">{{sl.new_stats.delivery_frq_errors | numFormat}}</span>
                    <span class="w-span-4">{{sl.new_stats.sl_subs_errors | numFormat}}</span>
                    <span class="w-span-3">{{sl.new_stats.offer_subs_errors | numFormat}}</span>
                    <span class="w-span-3">{{sl.new_stats.adset_errors | numFormat}}</span>
                    <span class="w-span-3">{{sl.new_stats.offer_errors | numFormat}}</span>
                    <span class="w-span-3">{{sl.new_stats.sl_offer_errors | numFormat}}</span>
                    <span class="w-span-4">{{sl.new_stats.other_errors | numFormat}}</span>
                </li>
                <li class="offer-bd-li" v-for='offerStats in sl.new_stats.offers_stats' v-show='openAdsets[sl.id]'>
                    <span class="small-width w-span"></span>
                    <span class="small-width w-span-1">{{offerStats.offer_id}}</span>
                    <span class="w-span-6">{{offerStats.offer_name}}</span>
                    <span class="w-span-6">-</span>
                    <span class="w-span-6">-</span>
                    <span class="w-span-5">{{offerStats.total_requests | numFormat}}</span>
                    <span class="w-span-5">{{offerStats.served | numFormat}}</span>
                    <span class="w-span-5">-</span>
                    <span class="w-span-2">-</span>
                    <span class="w-span-4">-</span>
                    <span class="w-span-4">-</span>
                    <span class="w-span-3">-</span>
                    <span class="w-span-4">-</span>
                    <span class="w-span-3">-</span>
                    <span class="w-span-3">-</span>
                    <span class="w-span-3">-</span>
                    <span class="w-span-3">-</span>
                    <span class="w-span-4">-</span>
                </li>
            </template>
        </ul>
    </div>

    <button v-if='userHasPermission("smartlinks.edit")' type="button" @click='newCampFormDisplay = !newCampFormDisplay' class="btn btn-primary smartlinks-toggle-form-display-button">New Smart Link</button>
    <NewCampaign v-if='newCampFormDisplay' :isVta='false' />

</div>
</template>

<script>
import NewCampaign from '../NewCampaign.smartlinks'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
const SL_STATUS_ACTIVE = "ACTIVE"
const SL_STATUS_PAUSED = "PAUSED"

export default {
    components: {
        NewCampaign, DateRangePicker
    },
    data() {
        let today = new Date()
        let yesterday = today.setDate(today.getDate() - 1)


        let yesterdayx = new Date();
        yesterdayx.setDate(yesterdayx.getDate() - 1);
        let todayx = new Date();
        return {
            reportLoading: false,
            newCampFormDisplay: 0,
            sort: {
                field: "id",
                asc: false
            },
            groupList: [],
            groups: [],
            options: [],
            slList: null,
            originalSLList: null,
            sumRow: null,
            openAdsets: {},
            approvedStatusToggle: 0,
            reportForm: {
                startDate: yesterday,
                endDate: today
            },
            filterForm: {
                id: null
            },
            dateRange: {
                startDate: yesterdayx,
                endDate: yesterdayx
            },
            autoApply: false,
            ranges: {
                'Today': [todayx, todayx],
                'Yesterday': [yesterdayx, yesterdayx],
                'Last 7 days': [new Date(Date.now() - 24 * 7 * 3600 * 1000), yesterdayx],
                'Last 7 days & toady': [new Date(Date.now() - 24 * 7 * 3600 * 1000), todayx],
                'Last 30 days': [new Date(Date.now() - 24 * 30 * 3600 * 1000), yesterdayx],
                'This month': [new Date(today.getFullYear(), today.getMonth(), 1), todayx],
                'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            },
            dateRangeDelta: {
                startDate: yesterdayx,
                endDate: yesterdayx
            },
            maxDate: new Date(),
            showZeros: false,
        }
    },
    watch: {
        ["filterForm.id"](val) {
            this.filterData(val)
            this.calculateRowsTotal()
        }
    },
    async created() {
        this.getGroupsData()
        let yes = new Date()
        yes.setDate(yes.getDate() - 1)

        this.reportForm = {
            startDate: yes.toISOString().substring(0, 10),
            endDate: yes.toISOString().substring(0, 10)
        }
        this.reportLoading = true
 
        try {
            var r = await this.$http.get(this.resources.SmartLinksCampaigns.getCampaigns, { 
                params: {
                    version: 'v2'
                }
            })
            let data = await r.json();
            var arr = data.filter(item => item.is_vta === false)
            this.slList = arr
            this.originalSLList = arr
            this.calculateRowsTotal()
            this.filterData()
            this.sortReport()
            this.reportLoading = false
        } catch (err) {
            this.notifyError("Could not load SL");
        }
        this.showZeroRows({target: {checked: false}})
    },
    filters: {
        numFormat(v) {
            let i = isNaN(v) ? 0 : v
            return (Number(i).toLocaleString())
        },
        percentageFormat(v) {
            if (!v) {
                v = 0
            }
            return v.toFixed(0) + "%"
        }
    },
    methods: {
        toggleForceGroup: async function (item) {
            try {
                item.group_forced = !item.group_forced
                await this.$http.put(this.resources.SmartLinksCampaigns.setForcedCampaignStatus(item.id), {
                    ...item
                })
                if(item.group_forced){
                    this.notifySuccess(`Campaign ${item.name} is no longer managed by its group scheduler`)
                }else{
                    this.notifySuccess(`Campaign ${item.name} is now managed by its group scheduler`)
                }    
            }
            catch(err){
                this.notifyError(`could not toggle force status button`)
            }
        },
        groupIdActive(groupId) {
            if (groupId === 0 || groupId === undefined) {
                return false
            }
            if (this.groups.find(x => x.id === groupId)?.isActive) {
                return true
            } else
                return false
        },
        getGroupStatus(groupId) {
            if (groupId === 0 || groupId === undefined) {
                return ''
            }
            for (let k in this.groups) {
                if (this.groups[k].id === groupId) {
                    return this.groups[k].status
                }
            }
            return ''
        },
        generateReport: async function (id, type) {
            // Get new reports link from admin back -> bi data service 
            try {

                const res = await this.$http.post(this.resources.SmartLinksCampaigns.newSlRequestsBlockedReport(id), undefined, {
                    params: {
                        date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                        end_date: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
                        request: type === "requests" ? "true" : "false"
                    }
                })
                
                if (res.ok) {
                    this.notifySuccess("The report will be sent to your email when ready", 10000);
                } else {
                    this.notifyError("Could not generate report");
                }

            } catch (err) {
                console.log(err);
                this.notifyError(err);
            }
        },
        filterData(val) {
            let filterID = this.filterForm.id
            if (val !== undefined && val === ''){
                this.showZeros = false;
                this.showZeroRows({target: {checked: false}});
            } 
            if (val !== undefined && val !== ''){
                this.showZeros = true;
                this.showZeroRows({target: {checked: true}});
            }
            this.slList.forEach(v => v.show = true)
            if (filterID) {
                filterID = filterID.toLowerCase()
                this.slList.forEach(v => v.show = v.id == filterID || v.name.toLowerCase().includes(filterID))
            }
        },
        onColumnClick(field) {
            if (this.sort.field === field) {
                this.sort.asc = !this.sort.asc
                
            } else {
                this.sort.field = field
                this.sort.asc = false
                if (field === 'group'){
                    this.onSortByGroup()
                }
            }
            this.sortReport()
        },
        sortReport() {
            let camps = this.slList

            let sort = this.sort
            if (!sort.field) {
                return
            }

            let sortField = sort.field

            camps.sort((a, b) => {
                if (!["id", "name", "status"].includes(sortField)) {
                    a = a.new_stats
                    b = b.new_stats
                }
                a = a[sortField] || 0
                b = b[sortField] || 0

                let f
                if (a > b) {
                    f = 1
                } else if (a < b) {
                    f = -1
                } else {
                    f = 0
                }

                if (!sort.asc) {
                    f = f * -1
                }

                return f
            })
        },
        onSortByGroup(){
            let camps = this.slList
            camps.sort(function(x,y){
                return x.group_id > y.group_id  ? -1 : y.group_id > x.group_id ? 1 : 0;
            });
        },
        onReportSubmit: async function (startDate, endDate) {
            if (this.reportLoading) {
                return
            }
            this.reportLoading = true
            try {
                const r = await this.$http.get(this.resources.SmartLinksCampaigns.getCampaigns, {
                    params: {
                        start_date: moment(startDate).format('YYYY-MM-DD'),
                        end_date: moment(endDate).format('YYYY-MM-DD'),
                        version: 'v2'
                    }
                })

                const data = await r.json()
                this.slList = data.filter(item => item.is_vta === false)
                this.originalSLList = this.slList;
                this.filterData()
                this.calculateRowsTotal()
                this.sortReport()
                this.reportLoading = false
            } catch (err) {
                this.notifyError("Could not fetch smartlinks")
            }
            this.showZeroRows({target: {checked: false}});
        },
        toggleAdsetExpand(id) {
            this.$set(this.openAdsets, id, !this.openAdsets[id])
        },
        onReportDownloadClick: async function () {
            let epPath = this.resources.SmartLinksCampaigns.slStatsReport

            let {
                startDate,
                endDate
            } = this.reportForm
            try {
                const r = await this.$http.get(epPath, {
                    params: {
                        startDate,
                        endDate
                    }
                })
                const data = await r.json()
                let filepath = data.filepath
                let rootURL = this.$http.options.root
                let epPath = this.resources.SmartLinksCampaigns.slStatsDownloadReport(filepath)
                let fileTitle = "report-sl-" + startDate + "-" + endDate
                let url = rootURL + epPath + "?file_title=" + fileTitle
            } catch (err) {
                return
            }
        },
        onDatesChange(v) {
            let {
                from,
                to
            } = v
            this.reportForm.startDate = from
            this.reportForm.endDate = to
        },
        getGroupsData: async function () {
            try {
                const r = await this.$http.get(this.resources.SmartLinksCampaigns.getSlGroups);
                const data = await r.json();
                this.groups = await r.json();

                this.groupList = data;
                this.groupList.unshift({
                    id: 0,
                    name: "none"
                })
            } catch (err) {
                this.notifyError("Could fetch groups.");
            }
        },
        toggleSlStatus(sl) {
            if (!this.userHasPermission("smartlinks.status-change")) {
                return
            }
            let toStatus
            if (sl.status === SL_STATUS_ACTIVE) {
                toStatus = SL_STATUS_PAUSED
            } else {
                toStatus = SL_STATUS_ACTIVE
            }
            if (this.approvedStatusToggle) {
                if (!window.confirm(`Are you sure that you want to change campaign "${sl.name}" status to: ${toStatus}?`)) {
                    return
                }
                this.approvedStatusToggle++
            }
            this.$http.put(this.resources.SmartLinksCampaigns.setCampaignStatus(sl.id), {
                    status: toStatus
                })
                .then(() => {
                    sl.status = toStatus
                })
        },
        calculateRowsTotal() {
            let data = this.slList
            let r = {
                total_requests: 0,
                served_total: 0,
                blocked_total: 0,
                idfa_frq_errors: 0,
                ip_frq_errors: 0,
                delivery_frq_errors: 0,
                sl_subs_errors: 0,
                offer_subs_errors: 0,
                adset_errors: 0,
                offer_errors: 0,
                sl_offer_errors: 0,
                other_errors: 0,
            }

            data.filter(v => v.show).forEach(row => {
                let stats = row.new_stats
                r.total_requests += stats.total_requests
                r.served_total += stats.served_total
                r.blocked_total += stats.blocked_total
                r.idfa_frq_errors += stats.idfa_frq_errors
                r.ip_frq_errors += stats.ip_frq_errors
                r.delivery_frq_errors += stats.delivery_frq_errors
                r.sl_subs_errors += stats.sl_subs_errors
                r.offer_subs_errors += stats.offer_subs_errors
                r.adset_errors += stats.adset_errors
                r.offer_errors += stats.offer_errors
                r.sl_offer_errors += stats.sl_offer_errors
                r.other_errors += stats.other_errors
            })

            if (r.total_requests > 0) {
                r.blocked_total_percentage = (r.blocked_total / r.total_requests) * 100
            }

            this.sumRow = r
        },
        setGroupHandler: async function (sl) {
            try {
                sl.group_forced = false
                const r = await this.$http.put(this.resources.SmartLinksCampaigns.setCampaignGroup(sl.id), {
                    group_id: sl.group_id
                })
                this.notifySuccess(`Campaign ${sl.name} group changed succesfully.`)
            } catch (err) {
                this.notifyError(`Campaign ${sl.name} group didn't change. please try again`)
            }
        },
        onRowClick(v) {
            this.$router.push({
                name: "SmartLinksCampaignView",
                params: {
                    campId: v.id
                }
            })
        },
        logEvent(x, y) {
            if (x === 'toggle' && !y) {
                setTimeout(async () => {
                    if (this.dateRangeDelta.startDate !== this.dateRange.startDate || this.dateRangeDelta.endDate !== this.dateRange.endDate) {
                        this.dateRangeDelta.startDate = this.dateRange.startDate
                        this.dateRangeDelta.endDate = this.dateRange.endDate
                        await this.onReportSubmit(this.dateRange.startDate, this.dateRange.endDate)
                    }
                }, 100);
            }
        },
        showZeroRows(e) {
            if (this.showZeros || e.target.checked) {
                this.slList = this.originalSLList;
            }
            if (!this.showZeros || !e.target.checked && this.slList && this.slList.length > 0) {
                this.slList = this.slList.filter(v => {
                    return v.new_stats.total_requests > 0 || v.new_stats.served_total > 0 || v.new_stats.blocked_total > 0
                })
            }
        }
    }
}
</script>

<style scoped>
.r-vta-mark {
    color: #00a1ff;
    font-size: 10px;
}

.w-span {
    width: 30px; 
    min-width: 10px; 
    flex: none;
    padding: 4px;
}

.w-span-1 {
    width: 40px; 
    min-width: 10px; 
    flex: none;
    padding: 4px;
}

#w-span-1 {
    width: 40px; 
    min-width: 10px; 
    flex: none;
    padding: 4px;
}

.w-span-2 {
    width: 40px; 
    min-width: 10px; 
    flex: none;
    padding: 4px;
}

.w-span-3 {
    width: 14ch; 
    min-width: 8ch; 
    flex: none;
    padding: 4px;
}

.w-span-4 {
    width: 12ch; 
    min-width: 6ch; 
    flex: none;
    padding: 4px;
}

.w-span-5 {
    width: 16ch;
    min-width: 14ch; 
    flex: none;
    padding: 4px;
}

.w-span-6 {
    width: 25ch;
    min-width: 17ch; 
    flex: none;
    padding: 4px;
}
.sl-list {
    font-size: 10px;
}
.btn-no-padding {
    padding: 0px;
}
</style>
