<template>
  <div>
    <div v-if="show_loader">
      <h1>Loading...</h1>
    </div>
    <div v-if="!show_loader">
      <div style="width: 100%;">
        <div class="back">
          <a href="/man/update-manual-revenue">
            <i class="fa fa-chevron-circle-left back-btn-2" aria-hidden="true"></i>
          </a>
        </div>
        <div>
          <div>
            <table class="app_data_table">
              <tr>
                <td class="hh_td">
                  Company:
                </td>
                <td class="hh_td">
                  Bundle:
                </td>
                <td class="hh_td">
                  App Name:
                </td>
                <td class="hh_td">
                  OS:<br />
                </td>
                <td class="hh_td">
                  Last Update:
                </td>
                <td class="hh_td">
                  Active:
                </td>
              </tr>
                <tr>
                  <td class="hh_td">
                    <b-form-select :options="dropdowns_data.companies" v-model="selected_company" :disabled="locked_data"
                      v-on:change="company_changed"></b-form-select>
                  </td>
                  <td class="hh_td">
                    <b-form-input v-model="selected_bundle" :disabled="locked_data"
                      v-on:keyup="bundle_change"></b-form-input>
                </td>
                <td class="hh_td">
                  {{ selected_appname }}
                </td>
                <td class="hh_td">
                  {{ selected_os }}
                </td>
                <td class="hh_td">
                  {{ last_update }}
                </td>
                <td class="hh_td">
                  <input class="custom-checkbox" type="checkbox" v-model="is_active" />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div style="font-size: 10px; display: none;">
                    <button @click="toggle_optional_bundles" v-if="show_optional_bundles_button" class="btn brn-xs">Show
                      optional bundles</button><br />
                    <div v-if="show_optional_bundles">
                      {{ bundle_options.join(', ') }}
                    </div>
                  </div>

                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <div style="font-size: 8px;">
                    Active = Pull the revenue to the Advertiser revenue column on the dashboard<br />
                    Not Active = Use Affise Revenue 
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="datepicker-div">
            <date-range-picker ref="picker" v-model="dateRange" opens="right" v-if="!new_app_data_row"
              :locale-data="{ firstDay: 0, format: 'dd.mm.yyyy' }" :ranges="ranges" @update="logEvent"
              @toggle="logEvent('toggle', $event)" @start-selection="logEvent('event: startSelection', $event)"
              @finish-selection="logEvent('event: finishSelection', $event)"
              :maxDate="maxDate">
            </date-range-picker>
          </div>
          <div class="datepicker-div" style="overflow: auto;">
            <div v-if="this.relevant_geos.length === 0 && !new_app_data_row">
              Please select geos to add to the table
            </div>
            <table class="data_table_c" v-if="this.relevant_geos.length !== 0">
              <tr>
                <td>

                </td>
                <td v-for="tt in revenue_table_data_hh">
                  {{ tt }}
                </td>
              </tr>
              <tr v-for="(r, k1) in revenue_table_data">
                <td>
                  {{ k1 }}
                </td>
                <td v-for="(rr, k2) in r">
                  <div v-if="adv">
                    <span v-if="revenue_table_data[k1][k2] == -999"></span>
                    <span v-else-if="k2 === 'sub_total' || k1 == 'Totals'">{{ revenue_table_data[k1][k2] }}</span>
                    <input v-else v-model="revenue_table_data[k1][k2]" class="pub-id-input form-control inp-ww" v-on:keyup="update_sub_totals(k1, k2)">
                  </div>
                  <div v-else>
                    <span v-if="revenue_table_data[k1][k2] == -999"></span>
                    <span v-else-if="k2 === 'sub_total' || dd_yesterday !== k2">{{ revenue_table_data[k1][k2] }}</span>
                    <input v-else v-model="revenue_table_data[k1][k2]" class="pub-id-input form-control inp-ww" v-on:keyup="update_sub_totals(k1, k2)">
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div style="display: none;">
          {{ tt }}
        </div>
      </div>
      <div>
        <div class="btn-save-cls datepicker-div">
          <div class="new_geo_row">
            <b-form-select v-if="! new_app_data_row" aria-required="true" class="select-w form-row" v-model="selected_geo" :options="geos"
              @change="new_geo_row_action">
            </b-form-select>
          </div>
          <button v-if="new_app_data_row" @click="save_rev" class="btn-success btn">Save and continue</button>
          <button v-else @click="save_rev" class="btn-success btn">Save</button>
        </div>
        <div v-if="! new_app_data_row">
          <div v-if="! adv">
            <a :href="'/man/update-manual-revenue-edit?id='+ row_id +'&adv=1'">Advanced Update</a>
          </div>
          <div v-else>
            <a :href="'/man/update-manual-revenue-edit?id='+ row_id">Basic Update</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ResizableDataTable from '@/components/shared/ResizableDataTable.vue';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';

export default {
  components: { ResizableDataTable, DateRangePicker },
  data() {
    let today = new Date()
    let yesterday = today.setDate(today.getDate() - 1)

    let yesterdayx = new Date();
    yesterdayx.setDate(yesterdayx.getDate() - 1);
    let todayx = new Date();

    const dd_from = new Date();
    dd_from.setDate(dd_from.getDate() - 7);
    const dd_to = new Date();
    dd_to.setDate(dd_to.getDate() - 1);

    return {
      show_loader: true,
      dash_data: [],
      deleteConfirm: false,
      showDeleteModal: false,
      deleteAdvName: '',
      deleteAdvId: 0,
      dataOptions: {
        sortdatafield: "adv_company_name",
        sortorder: "asc"
      },
      form: {
        date_from: yesterday,
        date_to: yesterday
      },
      fields: [],
      page_loaded: false,
      show_col: {},
      boolFields: ['adv_var_adv'],
      nonIntFields: ['adv_company_name', 'adv_csm_name', 'affise_adv_name'],
      revenueFields: ['revenue'],
      selected_list: [],
      current_list: [],
      adv_list: [],
      csm_list: [],
      filter_value: null,
      origin_list: [],
      list_types: [
        {
          value: 'adv_company_name',
          text: 'Company Name'
        },
        {
          value: 'adv_csm_name',
          text: 'CSM Name'
        }
      ],
      dateRange: {
        startDate: dd_from,
        endDate: dd_to
      },
      ranges: {
        'Yesterday': [yesterdayx, yesterdayx],
        'Last 7 days': [new Date(Date.now() - 24 * 7 * 3600 * 1000), yesterdayx],
        'Last 30 days': [new Date(Date.now() - 24 * 30 * 3600 * 1000), yesterdayx],
        'This month': [new Date(today.getFullYear(), today.getMonth(), 1), yesterdayx],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
      },
      dateRangeDelta: {
        startDate: yesterdayx,
        endDate: yesterdayx
      },
      geoOs: [],
      geoOsSelected: 'All',
      ma_on_display: '',
      dd_from: 'ddd',
      dd_to: 'bbb',
      no_actions: true,
      selected_geo_os: '',
      dropdowns_data: {
        companies: [],
      },
      selected_company: '',
      selected_bundle: '',
      bundle_options: [],
      selected_appname: '',
      selected_os: '',
      last_update: '',
      revenue_table_data: {},
      revenue_table_data_hh: [],
      geos: [],
      selected_geo: 'New Geo Row',
      tt: 0,
      relevant_geos: [],
      is_active: true,
      locked_data: true,
      revenue_table_data_db: [],
      show_optional_bundles_button: false,
      show_optional_bundles: false,
      dd_yesterday: moment().subtract(1, 'days').format('DD/MM'),
      new_app_data_row: true,
      row_id: 0,
      adv: 0,
      maxDate: new Date(),
      advertisers: [],
    }
  },
  async created() {
    this.page_loaded = false;
    // await this.getData();
    this.fields = [
      {
        name: 'company',
        label: 'Company',
        sortable: true,
        toggle_column: 1,
        show_c: true,
        lock_column: false
      },
      {
        name: 'bundle',
        label: 'Bundle',
        sortable: true,
        toggle_column: 2,
        show_c: true,
        lock_column: false
      },
      {
        name: 'app_name',
        label: 'App Name',
        sortable: true,
        toggle_column: 3,
        show_c: true,
        lock_column: false
      },
      {
        name: 'os',
        label: 'OS',
        sortable: true,
        toggle_column: 4,
        show_c: true,
        lock_column: false
      },
      {
        name: 'last_update',
        label: 'Last Update',
        sortable: true,
        toggle_column: 5,
        show_c: true,
        lock_column: false
      }
    ];

    for (const field of this.fields) {
      this.show_col[field.toggle_column] = true;
    }

    this.page_loaded = true;
    await this.getData();
    await this.set_revenue_table_data();
    let { id, adv } = this.$route.query;
    if (id !== '0') {
      this.row_id = id;
      await this.set_loaded_revenue();
      this.new_app_data_row = false;
    }
    if (adv) {
      this.adv = 1;
    }
  },
  methods: {
    async set_revenue_table_data() {
      const dates = [];
      this.revenue_table_data_hh = [];
      let dd = moment(this.dateRange.startDate);
      while (dd <= moment(this.dateRange.endDate)) {
        dates.push(dd.format('DD/MM'));
        this.revenue_table_data_hh.push(dd.format('DD/MM'));
        dd = dd.add(1, 'days');
      }

      this.revenue_table_data_hh.push('Sub Total');

      // this.revenue_table_data = {};

      for (let k1 of this.relevant_geos) {
        if (this.revenue_table_data[k1]) {
          continue;
        }
        this.revenue_table_data[k1] = {};
        for (let k2 of dates) {
          if (!this.revenue_table_data[k1][k2]) {
            this.revenue_table_data[k1][k2] = 0;
            for (let k3 in this.revenue_table_data_db) {
              if (this.revenue_table_data_db[k3].geo === k1 
                && (this.revenue_table_data_db[k3].dd_str.split('/').slice(0, 2).join('/') === k2 || this.revenue_table_data_db[k3].dd_str.split('.').slice(0, 2).join('/') === k2)) {
                if (! this.revenue_table_data[k1][k2]) {
                  this.revenue_table_data[k1][k2] = this.format_number(this.revenue_table_data_db[k3].revenue);
                }
              }
            }
          }
        }
        this.revenue_table_data[k1]['sub_total'] = 0;
      }
      
      if (Object.keys(this.revenue_table_data).length !== 0) {
        const firstKey = Object.keys(this.revenue_table_data)[0];
        const keys = Object.keys(this.revenue_table_data[firstKey]);
        let totals = [];
        keys.forEach(key => {
            let sum = 0;
            for (let subKey in this.revenue_table_data) {
                sum += this.parseValue(this.revenue_table_data[subKey][key]);
            }
            totals.push(sum);
        });
        for (let k1 in totals) {
          totals[k1] = this.format_number(totals[k1]);
        } 
        totals[totals.length - 1] = -999;
        this.revenue_table_data['Totals'] = totals;
      }
    },
    parseValue(value) {
        if (typeof value === 'string') {
            value = value.replace(/,/g, '');
        }
        const t = parseInt(value, 10);
        return typeof t === 'number' && !isNaN(t) ? t : 0;
    },
    async getData(date_from = null, date_to = null) {
      const response = await this.$http.get(this.resources.Overrides.getAvailableBundles);
      this.advertisers = await response.json();
      this.dropdowns_data.companies = this.advertisers.map(adv => adv.advertiser_name).sort();
      this.geos = ['New Geo Row', ...Array.from(new Set(this.advertisers.flatMap(adv => adv.bundles.map(bundle => bundle.geo).filter(geo => geo !== ''))))];

      const { id } = this.$route.query;
      if (id == '0') {
        this.locked_data = false;
      } else {
        const presetData = await this.$http.post(this.resources.Dash.getPreset, {
          preset_id: id,
          dd_from: moment(this.dateRange.startDate).format('DD/MM/YYYY'),
          dd_to: moment(this.dateRange.endDate).format('DD/MM/YYYY')
        });
        const presetDataJson = await presetData.json();
        this.selected_company = presetDataJson.row.company;
        this.company_changed();
        this.selected_bundle = presetDataJson.row.bundle_id;
        this.selected_appname = presetDataJson.row.app_name;
        this.selected_os = presetDataJson.row.os;
        this.is_active = presetDataJson.row.is_active === 1 ? true : false;
        this.last_update = presetDataJson.row.last_update;
        if (presetDataJson.row.geos !== '') {
          this.relevant_geos = presetDataJson.row.geos.split(',');
          this.revenue_table_data_db = presetDataJson.revenue_table_data;
        }
      }
      this.show_loader = false;
    },
    async updateData() {
      this.page_loaded = false;
      await this.getData(this.dateRange.startDate, this.dateRange.endDate);
      this.page_loaded = true;
    },
    onDatesChange(v) {
      let {
        from,
        to
      } = v
      this.form.date_from = from
      this.form.date_to = to
    },
    logEvent(x, y) {
      if (x === 'toggle' && !y) {
        setTimeout(async () => {
          if (this.dateRangeDelta.startDate !== this.dateRange.startDate || this.dateRangeDelta.endDate !== this.dateRange.endDate) {
            this.dateRangeDelta.startDate = this.dateRange.startDate
            this.dateRangeDelta.endDate = this.dateRange.endDate
            // this.fetchGlobalOffersData(this.dateRange.startDate, this.dateRange.endDate)\
            this.show_loader = true;
            await this.getData();
            await this.set_revenue_table_data();
            await this.update_sub_totals();
            this.show_loader = false;
            await this.fixTable();
          }
        }, 100);
      }
    },
    geoOsChanged() {
      const selectRow = this.geoOs.find(x => x.os_geo === this.geoOsSelected);
      if (selectRow.ma !== '' && selectRow.os !== '') {
        this.ma_on_display = `MA: ${selectRow.ma}, OM: ${selectRow.om}`;
      } else {
        this.ma_on_display = ``;
      }
    },
    dd() {

    },
    company_changed() {
      this.bundle_options = this.advertisers.find(x => x.advertiser_name === this.selected_company).bundles;
      if (this.$route.query.id === '0') {
        this.show_optional_bundles_button = true;
      }
      this.selected_appname = '';
      this.selected_bundle = '';
      this.selected_os = '';
    },
    bundle_change() {
      const row = this.bundle_options.find(x => x.bundle_id === this.selected_bundle);
      if (row) {
        this.selected_appname = row.app_name;
        this.selected_os = row.os;
      } else {
        this.selected_appname = '';
        this.selected_os = '';
      }
    },
    async new_geo_row_action() {
      this.relevant_geos.push(this.selected_geo);
      await this.set_revenue_table_data();
      await this.update_sub_totals();
      this.selected_geo = 'New Geo Row';
      this.tt++;
      if (document.activeElement != document.body) {
        document.activeElement.blur();
      }
      const totals = this.revenue_table_data['Totals'];
      delete this.revenue_table_data['Totals'];
      this.revenue_table_data['Totals'] = totals;
    },
    update_sub_totals(kk1, kk2) {
      for (let k1 in this.revenue_table_data) {
        let sub_total = 0;
        for (let k2 in this.revenue_table_data[k1]) {
          if (k2 === 'sub_total') {
            continue;
          }
          let t = this.revenue_table_data[k1][k2] + "";
          t = t.replace(/,/g, '');
          t = parseFloat(t);
          sub_total += t;
        }
        let tt = this.format_number(sub_total);
        this.revenue_table_data[k1]['sub_total'] = tt;
      }

      if (Object.keys(this.revenue_table_data).length !== 0) {
        const firstKey = Object.keys(this.revenue_table_data)[0];
        const keys = Object.keys(this.revenue_table_data[firstKey]);
        let totals = [];
        keys.forEach(key => {
            let sum = 0;
            for (let subKey in this.revenue_table_data) {
                sum += this.parseValue(this.revenue_table_data[subKey][key]);
            }
            totals.push(sum);
        });
        for (let k1 in totals) {
          totals[k1] = this.format_number(totals[k1]);
        } 
        totals[totals.length - 1] = -999;
        this.revenue_table_data['Totals'] = totals;
      }

      this.tt++;
      try {
        this.revenue_table_data[kk1][kk2] = this.format_number(this.revenue_table_data[kk1][kk2]);
      } catch (err) {}
    },
    async save_rev() {
      if (this.selected_company === '') {
        this.notifyError('Please select company');
        return;
      }
      if (this.selected_bundle === '') {
        this.notifyError('Please enter a bundle');
        return;
      }
      if (!this.bundle_options.map(bundle => bundle.bundle_id).includes(this.selected_bundle)) {
        this.notifyError(`Bundle ${this.selected_bundle} is not available for company ${this.selected_company}`);
        return;
      }
      this.show_loader = true;
      const revenue_table_data_c = {};
      for (let k1 in this.revenue_table_data) {
        if (k1 === 'Totals') {
          continue;
        }
        revenue_table_data_c[k1] = {};
        let dd = moment(this.dateRange.startDate);
        while (dd <= moment(this.dateRange.endDate)) {
          const dd_str = dd.format('DD/MM/YYYY');
          const dd_str2 = dd.format('DD/MM');
          dd = dd.add(1, 'days');
          revenue_table_data_c[k1][dd_str] = this.revenue_table_data[k1][dd_str2] + "";
          revenue_table_data_c[k1][dd_str] = revenue_table_data_c[k1][dd_str].replace(/,/g, '');
        }
      }

      const save_req_data = {
        preset_row_id: this.$route.query.id,
        revenue_table_data: revenue_table_data_c,
        selected_company: this.selected_company,
        selected_bundle: this.selected_bundle,
        selected_appname: this.selected_appname,
        selected_os: this.selected_os,
        is_active: this.is_active ? 1 : 0
      };
      const response = await this.$http.post(this.resources.Dash.saveOverrides, save_req_data);
      if (response.body.id === '-1') {
        this.notifyError(`Company/Bundle ID form is already exists`);
        this.show_loader = false;
      } else if (response.body.id !== '0') {
        window.location = '/man/update-manual-revenue-edit?id=' + response.body.id;
      } else {
        this.show_loader = false;
        this.notifySuccess(`Company / AppName Revenue Successfully Updated`);
      }
    },
    set_loaded_revenue() {
      for (let k1 in this.revenue_table_data_db) {
        for (let k2 in this.revenue_table_data) {
          for (let k3 in this.revenue_table_data[k2]) {
            if (k3 === 'sub_total') {
              continue;
            }
            let dd = this.revenue_table_data_db[k1].dd_str.split('.').slice(0, 2).join('/');
            if (dd === k3 && k2 === this.revenue_table_data_db[k1].geo) {
              this.revenue_table_data[k2][k3] = this.format_number(this.revenue_table_data_db[k1].revenue);
            }
          }
        }
      }
      this.update_sub_totals();
    },
    toggle_optional_bundles() {
      this.show_optional_bundles = !this.show_optional_bundles;
    },
    format_number(inp) {
      let newValue = inp;
      if (newValue !== null) {
        const result = newValue.toString()
        .replace(/[^\d.]/g, "") 
        .replace(/(\.\d*)?$/, function(decimalPart) { 
          return decimalPart;
        })
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
        return result;
      }
      return 0;
    },
    getRevenueOverride(geo, dd) {
      for (let k in this.revenue_table_data_db) {
        if (this.revenue_table_data_db[k].geo === geo && this.revenue_table_data_db[k].dd_str === dd) {
          return this.revenue_table_data_db[k].revenue;
        }
      }
      return 0;
    },
    fixTable() {
      console.log(this.revenue_table_data_db);
      this.revenue_table_data =  {};
      let fixed_data = {};
      // Iterate date range
      let dd
      for (let geo of this.relevant_geos) {
          fixed_data[geo] = {};
          dd = moment(this.dateRange.startDate);
          while (dd <= moment(this.dateRange.endDate)) {
            const dd_str = dd.format('DD/MM');
            fixed_data[geo][dd_str] = this.getRevenueOverride(geo, dd.format('DD.MM.YYYY'));
            dd = dd.add(1, 'days');
          }
          fixed_data[geo]['sub_total'] = 0;
      }
      
      // Totals
      dd = moment(this.dateRange.startDate);
      fixed_data['Totals'] = {};
      while (dd <= moment(this.dateRange.endDate)) {
        const dd_str = dd.format('DD/MM');
        fixed_data['Totals'][dd_str] = 0;
        dd = dd.add(1, 'days');
      }

      this.revenue_table_data = fixed_data;
      this.update_sub_totals();
      this.tt++;
    }
  },
}
</script>
<style scoped>
th,
td {
  padding-right: 60px;
}

h1 {
  margin-bottom: 20px;
}

.sort-icon {
  position: absolute;
  display: inline-block;
  padding-left: 1%;
}

.clickable-th {
  cursor: pointer;
}

.daterangepicker-form {
  z-index: 1000 !important;
}

.dash_table {
  margin: 0 auto;
}

.dash_row {
  text-align: center;
}

.dash_cell {
  display: inline-block;
  padding: 15px;
  border: 1px solid lightgray;
  margin: 3px;
  border-radius: 4px;
}

.filter_div1 {
  display: inline-block;
  vertical-align: top;
  padding-top: 2px;
  padding-right: 5px;
}

.filter_div2 {
  display: inline-block;
  vertical-align: top;
  padding-top: 2px;
}

.select_style {
  padding: 6px;
  border: 1px solid lightgray;
}

.filter_div3 {
  display: inline-block;
  padding-left: 15px;
}

.blocks_div {
  padding-top: 20px;
  padding-bottom: 20px;
}

.back-btn-2 {
  font-size: 41px;
  color: #3b67a0;
  cursor: pointer;
}

.app_data_table {
  text-align: center;
}

.datepicker-div {
  padding-top: 20px;
}

.inp-ww {
  width: 120px;
}

.btn-save-cls {
  text-align: center;
}

.select-w {
  width: 200px;
}

.new_geo_row {
  padding-bottom: 20px;
  text-align: center;
}

table td {
  padding: 10px;
}

.hh_td {
  width: 200px;
  text-align: center;
}

.data_table_c td {
  border: 1px solid gray;
  text-align: center;
}
</style>